import React from 'react';
import { Text } from './';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useCsrImages } from '../queries/useCsrImages';
import { transition, media } from '../utils/mixins';
import { Mixins } from '@cheapreats/react-ui';

const csrLinkURL = {
    health: '#1health',
    environment: '#2environment',
    finance: '#3finance',
    community: '#4community',
};

const CsrCardLink = styled.a`
    display: block;
    padding: 0.8rem 0;
    margin: 0.5rem;
    border-radius: 8px;
    text-decoration: none;
    & > h3 {
        margin-top: 0.5rem;
    }
    ${media.tablet`
        & > h3 {
            font-size: 1rem;
        }
    `}
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
    }

    ${transition(['box-shadow'])}
`;

const Item = styled.div`
    width: 25%;
    ${Mixins.media('phone', 'width: 100%;')}
    margin-bottom: 2rem;
`;
const CardLinksContainer = styled.div`
    width: 100%;
    ${Mixins.flex('row', 'center')}
    ${Mixins.media('phone', Mixins.flex('column'))}
    margin-top: 2rem;
`;

const CsrCardLinks = () => {
    const data = useCsrImages();
    return (
        <CardLinksContainer>
            {data.images.nodes.map((image) => (
                <Item>
                    <CsrCardLink href={csrLinkURL[image.name.substr(1)]}>
                        <Img
                            key={image.id}
                            fluid={image.childImageSharp.fluid}
                        />
                        <Text align="center" color="#EE2434" as="h3">
                            {image.name.substr(1).toUpperCase()}
                        </Text>
                    </CsrCardLink>
                </Item>
            ))}
        </CardLinksContainer>
    );
};

export default CsrCardLinks;
