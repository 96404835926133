import React from 'react';
import Img from 'gatsby-image';
import { useCsrImages } from '../queries/useCsrImages';
import { CardWrapper, CardWrapperLarge } from '../layouts/Default/Styles';

const CardSpecifications = {
    CardStyles: {
        Small: {
            height: '37px',
            width: '43px',
            opacity: '10%',
        },
        ClearSmall: {
            height: '37px',
            width: '43px',
        },
        Large: {
            height: '100%',
            width: '100%',
        },
    },
    CardWrappers: {
        Small: CardWrapper,
        Large: CardWrapperLarge,
    },
};

const TYPE_SMALL = 'Small';
const TYPE_LARGE = 'Large';

const CsrCards = ({ type, imageWithFullOpacity }) => {
    const CardWrapper = CardSpecifications.CardWrappers[type];
    const data = useCsrImages();
    return (
        <CardWrapper>
            {type === TYPE_SMALL &&
                data.images.nodes.map((image) =>
                    image.name === imageWithFullOpacity ? (
                        <Img
                            key={image.id}
                            fluid={image.childImageSharp.fluid}
                            style={CardSpecifications.CardStyles['ClearSmall']}
                        />
                    ) : (
                        <div>
                            <Img
                                key={image.id}
                                fluid={image.childImageSharp.fluid}
                                style={CardSpecifications.CardStyles[type]}
                            />
                        </div>
                    ),
                )}
            {type === TYPE_LARGE &&
                data.images.nodes.map((image) =>
                    image.name === imageWithFullOpacity ? (
                        <Img
                            key={image.id}
                            fluid={image.childImageSharp.fluid}
                            style={CardSpecifications.CardStyles[type]}
                        />
                    ) : null,
                )}
        </CardWrapper>
    );
};

export default CsrCards;
