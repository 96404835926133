import React from 'react';
import CsrCardLinks from '../components/CsrCardLinks.jsx';
import CsrCards from '../components/CsrCards.jsx';
import {
    SectionTemplate,
    SectionContainer,
    SectionTextContent,
} from '../components/Section';
import { Heading } from '@cheapreats/react-ui';
import { Layout } from '../components/Layout';

const EthicsPage = () => (
    <Layout title="Ethics and Corporate Responsibility">
        <SectionContainer
            header={
                <Heading
                    type="h1"
                    color="primary"
                    margin="auto auto"
                    style={{ textAlign: 'center' }}
                >
                    Ethics and
                    <br />
                    Corporate Social Responsibility (CSR)
                </Heading>
            }
            footer={<CsrCardLinks />}
        />
        <SectionTemplate
            header={<CsrCards type="Small" imageWithFullOpacity="1health" />}
            textComponent={
                <>
                    <SectionTextContent
                        subHeadingText="Clear Nutritional Info"
                        paragraphText="We provide an in-depth research of all the nutritional information of our vendors. 
            This ensures that your consumers can make the best and healthiest
            decisions as well as be informed about what’s in their meal!"
                    />
                    <SectionTextContent
                        subHeadingText="Dietary and Allergy Restrictions"
                        paragraphText="CheaprEats provides dietary warnings on all foods on our app so 
            that people with certain dietary restrictions are informed
            throughout their ordering process!"
                    />
                </>
            }
            headingText="HEALTH"
            imageContent={
                <CsrCards type="Large" imageWithFullOpacity="1health" />
            }
            reverse
            mediaFlexDirection="column-reverse"
            id="1health"
        />
        <SectionTemplate
            header={
                <CsrCards type="Small" imageWithFullOpacity="2environment" />
            }
            imageContent={
                <CsrCards type="Large" imageWithFullOpacity="2environment" />
            }
            headingText="ENVIRONMENT"
            subHeadingText="Sustainable & Conscious Disposal"
            paragraphText="CheaprEats provides sustainability information regarding 
        food packaging and materials. Whether that coffee cup or soup container 
        in your meal is recyclable, be in the know!"
            mediaFlexDirection="column-reverse"
            id="2environment"
        />
        <SectionTemplate
            header={<CsrCards type="Small" imageWithFullOpacity="3finance" />}
            imageContent={
                <CsrCards type="Large" imageWithFullOpacity="3finance" />
            }
            headingText="FINANCE"
            textComponent={
                <>
                    <SectionTextContent
                        subHeadingText="No Vender Left Behind Policy"
                        paragraphText="Businesses go through hard times, we understand. If your business is ever in the red
        and on the verge of shutting down we provide you with the ability to use our 
        software for free for a few months to get you back on your feet! "
                    />
                    <SectionTextContent
                        subHeadingText="Price Promise Guarantee"
                        paragraphText="All the prices on our platform match your in stores pricing to 
        ensure your customers don’t develop negative
        responses to your business! "
                    />
                    <SectionTextContent
                        subHeadingText="Price Promise Guarantee"
                        paragraphText="All the prices on our platform match your in stores pricing to 
        ensure your customers don’t develop negative
        responses to your business! "
                    />
                    <SectionTextContent
                        subHeadingText="Discount Matching Program"
                        paragraphText="When you invest in your business with discounts, 
        we will match those investments up to a certain 
        amount to show our commitment to your growth and success!"
                    />
                </>
            }
            reverse
            mediaFlexDirection="column-reverse"
            id="3finance"
        />
        <SectionTemplate
            header={<CsrCards type="Small" imageWithFullOpacity="4community" />}
            imageContent={
                <CsrCards type="Large" imageWithFullOpacity="4community" />
            }
            headingText="COMMUNITY"
            textComponent={
                <>
                    <SectionTextContent
                        subHeadingText="Diversity and Inclusion "
                        paragraphText="As a multicultural team, we strongly support diversity and inclusion throughout 
        everything we do, not just as our values in hiring processes. 
        We strive to acquire the best talent possible, to 
        provide you with the best technology that 
        isn’t biased against minorities! "
                    />
                    <SectionTextContent
                        subHeadingText="Community Development Reimbursements"
                        paragraphText="Do you donate to local charities or offer free meals at student events, 
        let us know and learn more about how you can giveback 
        to your community and be rewarded for it! "
                    />
                    <SectionTextContent
                        subHeadingText="No Contracts or Lock-Ins"
                        paragraphText="We don’t believe in high set-up fees or restrictive contracts that 
        lock your business into an unfavourably relationship and this is why 
        you can get started for free and leave at any time!"
                    />
                </>
            }
            mediaFlexDirection="column-reverse"
            id="4community"
        />
    </Layout>
);

export default EthicsPage;
