import { graphql, useStaticQuery } from 'gatsby';

/**
 * Queries and returns CSR card images from the images/ethics folder
 * @returns {Object} - Images of CSR cards
 */
export const useCsrImages = () => {
    const data = useStaticQuery(graphql`
        query imageQuery {
            images: allFile(
                filter: { relativeDirectory: { eq: "ethics" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    id
                    name
                    childImageSharp {
                        fluid(maxHeight: 700, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);
    return data;
};
